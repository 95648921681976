import { domain } from '@/Framework/config';
import cookieConfig from '@/Framework/config/cookieConfig';
import sessionConfig from '@/Framework/config/sessionConfig';
import loginEndpointsConfig from '@/Framework/config/loginEndpointsConfig';
import recaptchaConfig from '@/Framework/config/recaptchaConfig';
import searchEngineConfig from '@/Framework/config/searchEngineConfig';
import tenant from '@/Framework/config/tenantConfig';
import apiConfig from '@/Framework/config/apiConfig';
import gaConfig from '@/Framework/config/gaConfig';
import socketConfig from '@/Framework/config/socketConfig';
import logConfig from '@/Framework/config/logConfig';
import assetsConfig from '@/Framework/config/assetsConfig';
import iceServersConfig from '@/Framework/config/iceServersConfig';

const config = {
  host: domain.globalDomain,
  defaultTenant: tenant.finsight.code,
  ...domain,
  api: apiConfig,
  assets: assetsConfig,
  session: sessionConfig,
  cookie: cookieConfig,
  ga: gaConfig,
  socket: socketConfig,
  tenant,
  loginEndpoints: loginEndpointsConfig,
  recaptcha: recaptchaConfig,
  searchEngine: searchEngineConfig,
  iceServers: iceServersConfig,
  log: logConfig,
};

export default config;
