import env from '@/Framework/env/env';
import { IAllocateConfig } from '@/allocate/domain/vo/IAllocateConfig';
import { domain } from '@/Framework/config';

const config: IAllocateConfig = {
  name: 'Allocate',
  code: 'allocate',
  abbr: 'ALC',
  supportEmail: env('CREDENTIALS__ALLOCATE__SUPPORT__EMAIL'),
  hostname: `${ domain.allocateSubdomain }.${ domain.globalDomain }`,
  useApiProxy: true,
  options: {
    isNewApp: false,
  },
  isConsentForEDeliveryRequired: false,
  ga: {
    trackingId: env('GA__TRACKING_ID_ALLOCATE'),
  },
  gtm: {
    trackingId: env('FRONTEND___GOOGLE_TAG_MANAGER__TENANT_ALLOCATE__GTM_ID'),
  },
  api: {
    external: `${ domain.protocol }//${ domain.allocateSubdomain }.${ domain.globalDomain }`,
    internal: `http://${ env('ALLOCATE_WS__HOSTNAME_INTERNAL') }`,
  },
};

export default config;
