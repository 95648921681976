export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAILURE = 'GET_COMPANIES_FAILURE';

export const GET_COMPANY = 'GET_COMPANY';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_FAILURE = 'GET_COMPANY_FAILURE';

export const CREATE_COMPANY = 'CREATE_COMPANY';
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_ERROR = 'CREATE_COMPANY_ERROR';

export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_ERROR = 'UPDATE_COMPANY_ERROR';

export const DELETE_COMPANY = 'DELETE_COMPANY';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAILURE = 'DELETE_COMPANY_FAILURE';

export const SET_COMPANIES_TABLE_SORT = 'SET_COMPANIES_TABLE_SORT';
export const SET_COMPANIES_TABLE_ITEMS_PER_PAGE = 'SET_COMPANIES_TABLE_ITEMS_PER_PAGE';
export const SET_COMPANIES_TABLE_PAGE = 'SET_COMPANIES_TABLE_PAGE';

export const RESET_COMPANIES_DATA = 'RESET_COMPANIES_DATA';
