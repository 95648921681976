import * as actionTypes from './actionTypes';

const initialState = {
  modules: null,
  group: null,
  contactPermissions: [],
  currentUserPermissions: [],
  isFetching: false,
};

export default function data(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_USER_PERMISSIONS_MODULES_SUCCESS:
      return {
        ...state,
        modules: { ...action.payload },
      };
    case actionTypes.GET_USER_PERMISSIONS_GROUP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        group: action.payload,
      };
    case actionTypes.GET_CONTACT_PERMISSIONS:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.SUBSCRIBE_SOCKET_UPDATE_CONTACT_PERMISSIONS:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.SUBSCRIBE_SOCKET_UPDATE_CONTACT_PERMISSIONS_SUCCESS:
    case actionTypes.SUBSCRIBE_SOCKET_UPDATE_CONTACT_PERMISSIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.GET_CURRENT_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        currentUserPermissions: action.payload,
      };
    case actionTypes.GET_CONTACT_PERMISSIONS_SUCCESS:
      return {
        ...state,
        contactPermissions: action.payload,
      };
    case actionTypes.TRIGGER_USER_PERMISSION_GROUP:
      return {
        ...state,
        contactPermissions: action.permissions,
      };
    default:
      return state;
  }
}
