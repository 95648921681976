import dataroomConfig from '@/dataroom/application/config/config';
import finsightConfig from '@/finsight/application/config/config';
import condorConfig from '@/condor/application/config/config';
import usersConfig from '@/users/application/config/config';
import dmPortalConfig from '@/dmPortal/application/config/config';
import dealroadshowConfig from '@/dealroadshow/application/config/config';
import evercallConfig from '@/evercall/application/config/config';
import allocateConfig from '@/allocate/application/config/config';
import orderBookConfig from '@/orderBook/application/config/config';
import openmarketConfig from '@/openMarket/application/config/config';

const tenantConfig = {
  ...dataroomConfig.tenant,
  finsight: finsightConfig,
  condor: condorConfig,
  users: usersConfig,
  dmPortal: dmPortalConfig,
  dealroadshow: dealroadshowConfig,
  tenantEvercall: evercallConfig,
  allocate: allocateConfig,
  orderBook: orderBookConfig,
  openmarket: openmarketConfig,
};

export default tenantConfig;
