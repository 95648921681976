import { combineReducers } from 'redux';
import data from './data/reducer';
import title from './title/reducer';
import links from './links/reducer';
import advanced from './advanced/reducer';
import permissions from './permissions/reducer';

export default combineReducers({
  data,
  title,
  links,
  advanced,
  permissions,
});
