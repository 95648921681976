import { domain } from '@/Framework/config/domain';

/*
 * Login endpoints list
 * */
const loginEndpointsConfig = [
  `${ domain.protocol }//${ domain.drsSubdomain }.${ domain.globalDomain }`,
  `${ domain.protocol }//${ domain.usersSubdomain }.${ domain.globalDomain }/login`,
  `${ domain.protocol }//${ domain.usersSubdomain }.${ domain.globalDomain }/login/condor`,
  `${ domain.protocol }//${ domain.condorSubdomain }.${ domain.globalDomain }/login/condor`,
];

export default loginEndpointsConfig;
