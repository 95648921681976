import * as actionTypes from './actionTypes';

const initialState = {
  item: {},
  isTwoFactorCodeFetching: false,
  isItemFetching: false,
  isUserUpdating: false,
};

export default function data(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_USER:
      return {
        ...state,
        isItemFetching: true,
      };
    case actionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        item: action.payload,
        isItemFetching: false,
      };
    case actionTypes.GET_USER_ERROR:
      return {
        ...state,
        isItemFetching: false,
      };
    case actionTypes.EDIT_USER:
      return {
        ...state,
        isUserUpdating: true,
      };
    case actionTypes.EDIT_USER_SUCCESS:
    case actionTypes.EDIT_USER_ERROR:
      return {
        ...state,
        isUserUpdating: false,
      };
    case actionTypes.REGENERATE_USER_ACTIVATION_CODE_SUCCESS:
      return {
        ...state,
        item: {
          ...state.item,
          ...action.payload,
        },
      };
    case actionTypes.GENERATE_TWO_FACTOR_CODE:
      return {
        ...state,
        isTwoFactorCodeGenerating: true,
      };
    case actionTypes.GENERATE_TWO_FACTOR_CODE_SUCCESS:
      return {
        ...state,
        item: {
          ...state.item,
          twoFactorCode: action.payload.code,
        },
        isTwoFactorCodeGenerating: false,
      };
    case actionTypes.GENERATE_TWO_FACTOR_CODE_FAILURE:
      return {
        ...state,
        isTwoFactorCodeGenerating: false,
      };
    case actionTypes.RESET_USER_DATA:
      return initialState;
    default:
      return state;
  }
}
