import * as actionTypes from './actionTypes';

const initialState = {
  apiAccessKey: '',
  isLoading: true,
};

export default function advanced(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_API_ACCESS_KEY:
    case actionTypes.SET_API_ACCESS_KEY:
      return {
        ...state,
        apiAccessKey: action.payload,
        isLoading: false,
      };
    case actionTypes.IS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
}
