export const GET_USER_PERMISSIONS_MODULES = 'GET_USER_PERMISSIONS_MODULES';
export const GET_USER_PERMISSIONS_MODULES_SUCCESS = 'GET_USER_PERMISSIONS_MODULES_SUCCESS';
export const GET_USER_PERMISSIONS_MODULES_FAILURE = 'GET_USER_PERMISSIONS_MODULES_FAILURE';

export const GET_USER_PERMISSIONS_GROUP = 'GET_USER_PERMISSIONS_GROUP';
export const GET_USER_PERMISSIONS_GROUP_SUCCESS = 'GET_USER_PERMISSIONS_GROUP_SUCCESS';
export const GET_USER_PERMISSIONS_GROUP_FAILURE = 'GET_USER_PERMISSIONS_GROUP_FAILURE';

export const GET_CONTACT_PERMISSIONS = 'GET_CONTACT_PERMISSIONS';
export const GET_CONTACT_PERMISSIONS_SUCCESS = 'GET_CONTACT_PERMISSIONS_SUCCESS';
export const GET_CONTACT_PERMISSIONS_FAILURE = 'GET_CONTACT_PERMISSIONS_FAILURE';

export const GET_CURRENT_USER_PERMISSIONS = 'GET_CURRENT_USER_PERMISSIONS';
export const GET_CURRENT_USER_PERMISSIONS_SUCCESS = 'GET_CURRENT_USER_PERMISSIONS_SUCCESS';
export const GET_CURRENT_USER_PERMISSIONS_FAILURE = 'GET_CURRENT_USER_PERMISSIONS_FAILURE';

export const SUBSCRIBE_SOCKET_UPDATE_CONTACT_PERMISSIONS = 'SUBSCRIBE_SOCKET_UPDATE_CONTACT_PERMISSIONS';
export const SUBSCRIBE_SOCKET_UPDATE_CONTACT_PERMISSIONS_SUCCESS = 'SUBSCRIBE_SOCKET_UPDATE_CONTACT_PERMISSIONS_SUCCESS';
export const SUBSCRIBE_SOCKET_UPDATE_CONTACT_PERMISSIONS_FAILURE = 'SUBSCRIBE_SOCKET_UPDATE_CONTACT_PERMISSIONS_FAILURE';

export const UPDATE_CONTACT_PERMISSIONS = 'UPDATE_CONTACT_PERMISSIONS';
export const UPDATE_CONTACT_PERMISSIONS_SUCCESS = 'UPDATE_CONTACT_PERMISSIONS_SUCCESS';
export const UPDATE_CONTACT_PERMISSIONS_FAILURE = 'UPDATE_CONTACT_PERMISSIONS_FAILURE';

export const TRIGGER_USER_PERMISSION_GROUP = 'TRIGGER_USER_PERMISSION_GROUP';
