import * as actionTypes from './actionTypes';

const initialState = {
  collection: [],
  isCollectionFetching: false,
};

export default function title(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_TITLES:
      return {
        ...state,
        isCollectionFetching: true,
      };
    case actionTypes.GET_TITLES_SUCCESS:
      return {
        ...state,
        collection: action.payload.collection,
        totalCount: action.payload.totalCount,
        isCollectionFetching: false,
      };
    case actionTypes.GET_TITLES_ERROR:
      return {
        ...state,
        isCollectionFetching: false,
      };
    default:
      return state;
  }
}
