import { domain } from '@/Framework/config';
import env from '@/Framework/env/env';
import { featureToggle as condorFeatureToggle } from '@/condor/application/config/featureToggle';
import { ICondorConfig } from '@/condor/domain/vo/ICondorConfig';

const config: ICondorConfig = {
  name: 'Condor',
  code: 'condor',
  supportEmail: env('FINSIGHT_WEB___SUPPORT__EMAIL'),
  hostname: `${ domain.condorSubdomain }.${ domain.globalDomain }`,
  useApiProxy: true,
  supportChatId: env('FRONT_APP__CHAT_ID_TENANT_FINSIGHT'),
  ga: {
    trackingId: null,
  },
  gtm: {
    trackingId: null,
  },
  featureToggle: condorFeatureToggle,
  api: {
    external: `${ domain.protocol }//${ domain.apiSubdomain }.${ domain.globalDomain }`,
    internal: `http://${ env('CONDOR_WS__HOSTNAME_INTERNAL') }`,
  },
  routes: {
    base: '/core',
    finsight: '/core/finsight',
    drs: '/core/dealroadshow',
  },
};

export default config;
