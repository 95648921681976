import config from '@/Framework/config';
import * as actionTypes from './actionTypes';

const initialLinkSectionState = {
  application: {
    label: config.tenant.tenant17g5.name,
    value: config.tenant.tenant17g5.code,
    callbackUrl: `${ config.protocol }//${ config.tenant.dmPortal.hostname }/17g5`,
  },
  isGenerating: false,
  link: '',
};

const initialState = {
  passwordReset: {
    ...initialLinkSectionState,
    isInvalidateModalVisible: false,
  },
  activation: {
    ...initialLinkSectionState,
  },
  verification: {
    ...initialLinkSectionState,
    application: {
      label: config.tenant.dealroadshow.name,
      value: config.tenant.dealroadshow.code,
      callbackUrl: `${ config.protocol }//${ config.tenant.dmPortal.hostname }/roadshow`,
    },
  },
};

export default function links(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_USER_LINKS_SECTION_APPLICATION:
      return {
        ...state,
        [action.payload.sectionName]: {
          ...state[action.payload.sectionName],
          application: action.payload.value,
        },
      };
    case actionTypes.SET_USER_INVALIDATE_PASSWORD_MODAL_IS_VISIBLE:
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          isInvalidateModalVisible: action.payload.isVisible,
        },
      };
    case actionTypes.GENERATE_USER_PASSWORD_RESET_LINK:
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          isGenerating: true,
        },
      };
    case actionTypes.GENERATE_USER_PASSWORD_RESET_LINK_SUCCESS:
      return {
        ...state,
        passwordReset: {
          ...state.passwordReset,
          isGenerating: false,
          link: action.payload,
        },
      };
    case actionTypes.GENERATE_USER_ACTIVATION_LINK:
      return {
        ...state,
        activation: {
          ...state.activation,
          isGenerating: true,
        },
      };
    case actionTypes.GENERATE_USER_ACTIVATION_LINK_SUCCESS:
      return {
        ...state,
        activation: {
          ...state.activation,
          isGenerating: false,
          link: action.payload,
        },
      };
    case actionTypes.RESET_USER_LINKS_STATE:
      return initialState;
    default:
      return state;
  }
}
