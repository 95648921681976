import { domain } from '@/Framework/config';
import env from '@/Framework/env/env';
import { IUsersConfig } from '@/users/domain/vo/IUsersConfig';

const config: IUsersConfig = {
  name: 'Users',
  code: 'users',
  supportEmail: env('FINSIGHT_WEB___SUPPORT__EMAIL'),
  hostname: `${ domain.usersSubdomain }.${ domain.globalDomain }`,
  useApiProxy: true,
  ga: {
    trackingId: env('GA__TRACKING_ID_DEALROADSHOW'),
  },
  gtm: {
    trackingId: env('FRONTEND___GOOGLE_TAG_MANAGER__TENANT_DEALROADSHOW__GTM_ID'),
  },
  api: {
    external: `${ domain.protocol }//${ domain.usersSubdomain }.${ domain.globalDomain }`,
    internal: `http://${ env('USERS__HOSTNAME_INTERNAL') }`,
  },
};

export default config;
