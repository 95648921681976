import { applyMiddleware, legacy_createStore as baseCreateStore, Reducer } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import Container from '@/Framework/DI/Container';
import { createRouterMiddleware, initialRouterState, RouterState } from 'connected-next-router';
import Router from 'next/router';
import { MakeStore } from 'next-redux-wrapper';

interface IParams {
  container: Container,
  reducer: Reducer,
  middleware?: Array<any>,
}

interface IInitialState {
  router?: RouterState,
  container: Container,
}

const bindMiddleware = (middleware: Array<any>) => {
  if (process.env.NODE_ENV !== 'production') {
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export const createStore = ({ container, reducer, middleware = [] }: IParams): MakeStore<any> => (context) => {
  const nextRouterMiddleware = createRouterMiddleware();
  // @ts-ignore
  const { asPath } = context.ctx || Router.router || {};
  let initialState = {
    container,
  };
  if (asPath) {
    initialState = {
      ...initialState,
      router: initialRouterState(asPath),
      container,
    } as IInitialState;
  }

  return baseCreateStore(reducer, initialState, bindMiddleware([
    nextRouterMiddleware,
    ...middleware,
  ]));
};
