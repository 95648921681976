export const RESET_USER_LINKS_STATE = 'RESET_USER_LINKS_STATE';

export const SET_USER_LINKS_SECTION_APPLICATION = 'SET_USER_LINKS_SECTION_APPLICATION';
export const SET_USER_INVALIDATE_PASSWORD_MODAL_IS_VISIBLE = 'SET_USER_INVALIDATE_PASSWORD_MODAL_IS_VISIBLE';

export const GENERATE_USER_PASSWORD_RESET_LINK = 'GENERATE_USER_PASSWORD_RESET_LINK';
export const GENERATE_USER_PASSWORD_RESET_LINK_SUCCESS = 'GENERATE_USER_PASSWORD_RESET_LINK_SUCCESS';
export const GENERATE_USER_PASSWORD_RESET_LINK_FAILURE = 'GENERATE_USER_PASSWORD_RESET_LINK_FAILURE';

export const INVALIDATE_USER_PASSWORD = 'INVALIDATE_USER_PASSWORD';
export const INVALIDATE_USER_PASSWORD_SUCCESS = 'INVALIDATE_USER_PASSWORD_SUCCESS';
export const INVALIDATE_USER_PASSWORD_FAILURE = 'INVALIDATE_USER_PASSWORD_FAILURE';

export const GENERATE_USER_ACTIVATION_LINK = 'GENERATE_USER_ACTIVATION_LINK';
export const GENERATE_USER_ACTIVATION_LINK_SUCCESS = 'GENERATE_USER_ACTIVATION_LINK_SUCCESS';
export const GENERATE_USER_ACTIVATION_LINK_FAILURE = 'GENERATE_USER_ACTIVATION_LINK_FAILURE';

export const GENERATE_INVESTOR_VERIFICATION_LINK = 'GENERATE_INVESTOR_VERIFICATION_LINK';
export const GENERATE_INVESTOR_VERIFICATION_LINK_SUCCESS = 'GENERATE_INVESTOR_VERIFICATION_LINK_SUCCESS';
export const GENERATE_INVESTOR_VERIFICATION_LINK_FAILURE = 'GENERATE_INVESTOR_VERIFICATION_LINK_FAILURE';
