import * as actionTypes from './actionTypes';

const initialState = {
  collection: [],
  totalCount: 0,
  sortBy: 'createdAt',
  sortOrder: 'desc',
  page: 1,
  perPage: 25,
  isCollectionFetching: false,
};

export default function data(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_COMPANIES:
      return {
        ...state,
        isCollectionFetching: true,
      };
    case actionTypes.GET_COMPANIES_FAILURE:
      return {
        ...state,
        isCollectionFetching: false,
      };
    case actionTypes.GET_COMPANIES_SUCCESS:
      return {
        ...state,
        isCollectionFetching: false,
        collection: action.payload.collection,
        totalCount: action.payload.totalCount,
      };
    case actionTypes.SET_COMPANIES_TABLE_SORT:
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
        page: 1,
      };
    case actionTypes.SET_COMPANIES_TABLE_ITEMS_PER_PAGE:
      return {
        ...state,
        perPage: action.payload,
        page: 1,
      };
    case actionTypes.SET_COMPANIES_TABLE_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case actionTypes.RESET_COMPANIES_DATA:
      return initialState;
    default:
      return state;
  }
}
