export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_ERROR = 'EDIT_USER_ERROR';

export const REGENERATE_USER_ACTIVATION_CODE = 'REGENERATE_USER_ACTIVATION_CODE';
export const REGENERATE_USER_ACTIVATION_CODE_SUCCESS = 'REGENERATE_USER_ACTIVATION_CODE_SUCCESS';
export const REGENERATE_USER_ACTIVATION_CODE_FAILURE = 'REGENERATE_USER_ACTIVATION_CODE_FAILURE';

export const RESET_USER_DATA = 'RESET_USER_DATA';

export const GENERATE_TWO_FACTOR_CODE = 'GENERATE_TWO_FACTOR_CODE';
export const GENERATE_TWO_FACTOR_CODE_SUCCESS = 'GENERATE_TWO_FACTOR_CODE_SUCCESS';
export const GENERATE_TWO_FACTOR_CODE_FAILURE = 'GENERATE_TWO_FACTOR_CODE_FAILURE';
