import flatten from 'lodash/flatten';
import base from '@/Framework/url/baseUrl';
import urlJoin from '@/Framework/url/helpers/urlJoin';
import config from '@/Framework/config';
import buildUrl from '@/Framework/url/helpers/buildUrl';
import shouldUseInternalNetwork from '@/Framework/Router/Next/shouldUseInternalNetwork';
import shouldUseProxy from '@/Framework/url/helpers/shouldUseProxy';

export default {
  getUrl(...uris: (string | string[])[]): string {
    if (!Array.isArray(uris)) {
      uris = [uris];
    }
    uris = flatten(uris).filter((uri) => !!uri);
    return buildUrl(config.protocol, `${ config.condorSubdomain }.${ config.globalDomain }`, config.tenant.condor.routes.drs, ...uris);
  },

  getBaseUrl(...uris: string[]): string {
    return buildUrl(config.protocol, `${ config.condorSubdomain }.${ config.globalDomain }`, ...uris);
  },

  getApiUrl(...uris: string[]): string {
    return base.getApiUrl('condor', ...uris);
  },

  getUrlPathname(...uris: (string | string[])[]): string {
    return urlJoin(config.tenant.condor.routes.drs, ...uris);
  },

  getRpcUrl(): string {
    if (shouldUseInternalNetwork()) {
      return this.getInternalRpcUrl();
    }

    if (shouldUseProxy()) {
      return this.getProxyApiRpcPath();
    }

    return base.getApiUrl('/condor/rpc');
  },

  getProxyApiRpcPath() {
    return `${ this.getProxyPath() }/rpc`;
  },

  getProxyPath() {
    return `${ window.location.protocol }//${ window.location.hostname }/proxy/api/condor`;
  },

  getInternalRpcUrl(): string {
    return `${ config.tenant.condor.api.internal }/rpc`;
  },

  getOpenMarketFilesUrl(...uris: string[]): string {
    return base.getApiUrl('/open-market/files', ...uris);
  },
};
