export interface IAction {
  type: string,
  payload?: any,
}

function createAction(type: string, payload?: any): IAction {
  return {
    type,
    ...payload !== undefined && { payload },
  };
}

export default createAction;
