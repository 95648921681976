import { domain } from '@/Framework/config/domain';

const apiConfig = {
  proxy: {
    rpc: {
      url: '/proxy/api/rpc',
    },
    rest: {
      url: '/proxy/api',
    },
  },
  rpc: {
    url: `${ domain.protocol }//${ domain.apiSubdomain }.${ domain.globalDomain }/rpc`,
  },
  rest: {
    url: `${ domain.protocol }//${ domain.apiSubdomain }.${ domain.globalDomain }`,
  },
};

export default apiConfig;
