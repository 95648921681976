import { domain } from '@/Framework/config/domain';

const sessionConfig = {
  idleTimeout: 5 * 60 * 1000, // 5m in ms
    prolongInterval: 15 * 60 * 1000, // 15m in ms
  viewerIdTtl: 10 * 30 * 24 * 60 * 60 * 1000, // 10mo in ms,
  loginEndpoints: [
  `${ domain.protocol }//${ domain.drsSubdomain }.${ domain.globalDomain }`,
  `${ domain.protocol }//${ domain.usersSubdomain }.${ domain.globalDomain }/login`,
  `${ domain.protocol }//${ domain.usersSubdomain }.${ domain.globalDomain }/login/condor`,
  `${ domain.protocol }//${ domain.condorSubdomain }.${ domain.globalDomain }/login/condor`,
  ],
};

export default sessionConfig;
