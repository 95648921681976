import { domain } from '@/Framework/config/domain';

const socketConfig = {
  // We force socket connection to dummy localhost in case the variables sre not yet replaced.
  // This hack can be removed after we clean up our codebase from getContainer() calls.
  // The socket connection is created during the build time, because we do instantate it in the root scope
  // with the createAsyncCollection factory
  url: domain.protocol.includes('http')
    ? `${ domain.protocol }//${ domain.socketSubdomain }.${ domain.globalDomain }/socket`
    : 'http://localhost',
};

export default socketConfig;
