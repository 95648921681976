import { IEventsReducerState } from './interfaces';

export const OPERATOR_TOKEN_QUERY_PARAM_NAME: string = 'ops';
export const EVENTS_PER_PAGE: number = 25;

export const EVENTS_REDUCER_INITIAL_STATE: IEventsReducerState = {
  page: 1,
  // older portion of events that existed to the moment of user login (sorted on BE)
  bufferData: [],
  // latest portion of events that existed to the moment of user login (sorted on BE)
  initialData: [],
  initialTotalCount: 0,
  // events occurred after login and pushed from sockets (sorted on FE, because they get mixed with draft messages)
  newData: [],
};

// action types for events sections (events, questions, privateMessages)
export const CHAT_EVENTS_ACTION_TYPES = {
  PUSH_NEW_EVENT: 'PUSH_NEW_EVENT', // this action is triggered by socket event
  MERGE_BUFFER_EVENTS: 'MERGE_BUFFER_EVENTS',
  SET_BUFFER_DATA: 'SET_BUFFER_DATA',
  SET_INITIAL_DATA: 'SET_INITIAL_DATA',
  RESET_EVERCALL_DASHBOARD_EVENTS: 'RESET_EVERCALL_DASHBOARD_EVENTS',
};
